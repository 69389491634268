export function reportSys(eventName) {
  let pageSource = window.location.pathname.replace("/", "");
  fetch(window.location.origin + `/webReport`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    cache: "default",
    body: JSON.stringify({
      channel: 0, // 事件来源 0: h5 | 1:App 【必传 int】
      pageLocation: window.location.href, //页面url 【必传 str】
      eventName: eventName, // 事件名称 【必传 str】
      pageSource, // 落地页 【必传 str】
      // utmSource: "google", // 广告来源 【str】
      // utmCode: window.fbqId, // 广告系列代码 【str】
    }),
  });
}
export function reportPage() {
  reportSys("openPage");
}
export function reportDownload() {
  reportSys("downloadClick");
}
export function reportDownloadApk() {
  reportSys("downloadApk");
}

export function getDeviceType() {
  //获取设备类型
  let userAgent = navigator.userAgent.toLowerCase();
  console.log(navigator.userAgent, "navigator.userAgent");
  let deviceType = null;

  if (/HUAWEI/i.test(userAgent) || /HONOR/i.test(userAgent)) {
    deviceType = "HUAWEI";
  } else if (/OPPO/i.test(userAgent)) {
    deviceType = "OPPO";
  } else if (/VIVO/i.test(userAgent)) {
    deviceType = "VIVO";
    // } else if (/Xiaomi/i.test(userAgent)) {
    //   deviceType = 'xiaomi';
    // } else if (/Samsung/i.test(userAgent)) {
    //   deviceType = 'Samsung';
    // }
  } else {
    deviceType = null;
  }

  return deviceType;
}
