import React from "react";
import { enquireScreen } from "enquire-js";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});
class Test extends React.PureComponent {
  state = { isMobile };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
  }


  redirectToAppMarket(deviceType) {
    let appPackageName = 'tech.codeblock.okpeso' //app 包名
    
    let appApplyUrl = appPackageName
    switch (deviceType) {
        case 'huawei':
            appApplyUrl = 'appmarket://details?id=' + appApplyUrl;
            break;
        case 'xiaomi':
            appApplyUrl = 'mimarket://details?id=' + appApplyUrl;
            break;
        case 'oppo':
              appApplyUrl = 'oppomarket://details?packagename=' + appApplyUrl;
              break;
        case 'vivo':
            appApplyUrl = 'vivomarket://details?id=' + appApplyUrl;
            break;
        case 'samsung':
            appApplyUrl = 'samsungapps://ProductDetail/' + appApplyUrl;
            break;
        default:
            break;
    }

    if (isMobile) {
      window.location.href = appApplyUrl;
    } else {
      //在桌面设备上，可能需要打开matsApp的网页版或提示用户
      window.open(appApplyUrl);
    }

  }

  render() {
    return (
      <div>
        <div
          style={{
            margin: 3,
            padding: 5,
            border: "1px solid blue",
            color: "blue",
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={() => this.redirectToAppMarket('huawei')}
        >
          <span
            style={{
              color: "blue",
            }}
          >
            Launch huawei
          </span>
        </div>
        <div
          style={{
            margin: 3,
            padding: 5,
            border: "1px solid green",
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={() => this.redirectToAppMarket('xiaomi')}
        >
          <span
            style={{
              color: "green",
            }}
          >
            Launch xiaomi
          </span>
        </div>
        <div
          style={{
            margin: 3,
            padding: 5,
            border: "1px solid red",
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={() => this.redirectToAppMarket('oppo')}
        >
          <span
            style={{
              color: "red",
            }}
          >
            Launch oppo
          </span>
        </div>
        <div
          style={{
            margin: 3,
            padding: 5,
            border: "1px solid black",
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={() => this.redirectToAppMarket('vivo')}
        >
          <span
            style={{
              color: "black",
            }}
          >
            Launch vivo
          </span>
        </div>
        <div
          style={{
            margin: 3,
            padding: 5,
            border: "1px solid purple",
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={() => this.redirectToAppMarket('samsung')}
        >
          <span
            style={{
              color: "purple",
            }}
          >
            Launch samsung
          </span>
        </div>
      </div>
    );
  }
}
export default Test;
